/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { v1 as uuid } from 'uuid';

const formatterQuestions = (questions: any) => {
  let newQuestions = {};
  Object.entries(questions).forEach(question => {
    const title = `$#${uuid()}$#${question[0]}`;
    const value = question[1];

    if (Array.isArray(value)) {
      newQuestions = {
        ...newQuestions,
        [title]: value.map(questionChildren =>
          formatterQuestions(questionChildren),
        ),
      };
    } else {
      newQuestions = { ...newQuestions, [title]: value };
    }
  });

  return newQuestions;
};

const updateQuestion = (
  questions: any,
  key: any,
  newValue: any,
  newTitle?: any,
) => {
  let newQuestions = {};
  Object.entries(questions).forEach(question => {
    const title = question[0];
    const value = question[1];

    if (title === key && Array.isArray(value)) {
      newQuestions = {
        ...newQuestions,
        [title]: newValue,
      };
    } else if (title === key) {
      newQuestions = { ...newQuestions, [newTitle || title]: newValue };
    } else {
      newQuestions = { ...newQuestions, [title]: value };
    }
  });

  return newQuestions;
};

const formatterSendQuestions = (questions: any) => {
  let newQuestions = {};
  Object.entries(questions).forEach(question => {
    const title = question[0].replace(/\$#.*\$#/, '');
    const value = question[1];

    if (Array.isArray(value)) {
      newQuestions = {
        ...newQuestions,
        [title]: value.map(questionChildren =>
          formatterSendQuestions(questionChildren),
        ),
      };
    } else {
      newQuestions = { ...newQuestions, [title]: value };
    }
  });

  return newQuestions;
};

const formatterLabel = (label: any) => {
  return label.replace(/\$#.*\$#/, '');
};

const formatterLabelMarks = (label: any) => {
  const value = formatterLabel(label);
  return value.replace(/#titulo|#subtitulo|#list-new-items-disabled/g, '');
};

export {
  formatterQuestions,
  updateQuestion,
  formatterSendQuestions,
  formatterLabel,
  formatterLabelMarks,
};
